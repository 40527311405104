import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';

interface Props {
  course: string;
  link: string;
}

export default function Course({ course, link }: Props) {
  const { t } = useTranslation('common');

  return (
    <div className="overflow-hidden w-full relative mx-auto shadow-lg rounded flex items-center gap-6 bg-platzi-dark-second highlight-white/5 cursor-pointer h-20">
      <Link
        href={`${link}?utm_source=jshero&utm_medium=organic&utm_campaign=JSHERO_CHALLENGES`}
        passHref
      >
        <a target="_blank" rel="noopener noreferrer">
          <div className="flex">
            <div className="w-12 h-12 mr-2 rounded-full ml-4">
              <Image
                layout="intrinsic"
                height={100}
                width={100}
                src="https://static.platzi.com/media/learningpath/emblems/ce907ec9-1ef8-4e27-9bcc-71493a4bd7dc.jpg"
                alt="Platzi Logo"
              />
            </div>
            <div className="flex flex-col py-2 pr-5">
              <strong className="text-slate-200 sm:text-md text-xs lg:text-sm font-medium mb-1">
                {t('course.title')}
              </strong>
              <span className="text-slate-400 sm:text-md text-xs lg:text-sm">{course}</span>
            </div>
          </div>
        </a>
      </Link>
    </div>
  );
}
